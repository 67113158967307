import { useEffect, useState, useRef } from "react";

export default function GooglePopupAd() {
  const [isVisible, setIsVisible] = useState(false);
  const adRef = useRef(null);
  const popupSlotRef = useRef(null); // Ref to store the popup ad slot
  const isInitialized = useRef(false);

  useEffect(() => {
    // Initialize googletag
    window.googletag = window.googletag || { cmd: [] };

    const initializeAd = () => {
      // Check if the DIV exists and hasn't been initialized yet
      if (adRef.current && !isInitialized.current) {
        isInitialized.current = true;

        googletag.cmd.push(function () {
          try {
            // Define the popup ad slot and store it in the popupSlotRef
            popupSlotRef.current = googletag
              .defineSlot(
                "/22082859479/ca-pub-5200956238394958-tag/ansh.quiztwiz_popup",
                [
                  [336, 280],
                  [1, 1],
                  [300, 250],
                ],
                "div-gpt-ad-1730893714393-2"
              )
              .addService(googletag.pubads());

            // Add event listener for slot render
            googletag
              .pubads()
              .addEventListener("slotRenderEnded", function (event) {
                if (event.slot === popupSlotRef.current) {
                  if (!event.isEmpty) {
                    setIsVisible(true);
                  }
                }
              });

            // Enable services
            googletag.enableServices();
            googletag.pubads().enableSingleRequest();

            // Refresh the specific slot
            googletag.pubads().refresh([popupSlotRef.current]);

            console.log("Ad initialization complete");
          } catch (error) {
            console.error("Error initializing ad:", error);
          }
        });
      }
    };

    // Delay the ad initialization
    const timer = setTimeout(() => {
      initializeAd();
    }, 1000);

    return () => {
      clearTimeout(timer);
      // Cleanup on unmount - destroy the specific popup slot if it exists
      if (popupSlotRef.current) {
        googletag.cmd.push(function () {
          googletag.destroySlots([popupSlotRef.current]);
        });
      }
    };
  }, []); // Empty dependency array since we only want this to run once

  const handleClose = () => {
    try {
      if (popupSlotRef.current) {
        // Destroy only the popup ad slot
        googletag.cmd.push(function () {
          googletag.destroySlots([popupSlotRef.current]);
        });
      }
      setIsVisible(false);
    } catch (error) {
      console.error("Error destroying popup ad slot:", error);
      setIsVisible(false);
    }
  };

  console.log("is visible", isVisible);

  // Always render the container, but control visibility with CSS
  return (
    <div className={`popup-overlay ${isVisible ? "flex" : "hidden"}`}>
      <div className={`popup-content`}>
        <button
          onClick={handleClose}
          className="absolute -top-8 right-0 bg-gray-800 text-white px-3 py-1 rounded-md shadow-md hover:bg-gray-700 z-50"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <div ref={adRef} id="div-gpt-ad-1730893714393-2" />
      </div>
    </div>
  );
}
