import { useEffect, useState } from "react";

export default function GoogleAds({
  adname = "ansh.quiztwiz_start_inpage",
  size = [
    [336, 280],
    [300, 250],
  ],
}) {
  const [isVisible, setIsVisible] = useState(true);
  const [isAdLoaded, setIsAdLoaded] = useState(false);

  useEffect(() => {
    if (!isVisible) return;

    const displayAd = () => {
      try {
        window.googletag = window.googletag || { cmd: [] };
        googletag.cmd.push(function () {
          // Convert string size to actual array if needed
          const adSizes = Array.isArray(size) ? size : JSON.parse(size);

          googletag
            .defineSlot(
              `/22082859479/ca-pub-5200956238394958-tag/${adname}`,
              adSizes,
              `div-gpt-ad-${adname}`
            )
            .addService(googletag.pubads());

          googletag
            .pubads()
            .addEventListener("slotRenderEnded", function (event) {
              if (
                event.slot.getAdUnitPath() ===
                `/22082859479/ca-pub-5200956238394958-tag/${adname}`
              ) {
                if (!event.isEmpty) {
                  setIsAdLoaded(true);
                }
              }
            });

          googletag.pubads().enableSingleRequest();
          googletag.enableServices();

          // Use the correct div ID for display
          googletag.display(`div-gpt-ad-${adname}`);
        });
      } catch (error) {
        console.error("Error displaying ad:", error);
      }
    };

    const timeoutId = setTimeout(displayAd, 100);
    return () => clearTimeout(timeoutId);
  }, [isVisible, adname, size]);

  if (!isVisible) return null;

  return <div id={`div-gpt-ad-${adname}`} />;
}
