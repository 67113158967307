import { useEffect, useState, useRef } from "react";

export default function AnchorAdx() {
  const [isVisible, setIsVisible] = useState(true);
  const [isAdLoaded, setIsAdLoaded] = useState(false);
  const anchorSlotRef = useRef(null); // Ref to store the anchor ad slot

  useEffect(() => {
    if (!isVisible) return;

    const displayAd = () => {
      try {
        console.log("Initializing Google Tag...");
        window.googletag = window.googletag || { cmd: [] };

        googletag.cmd.push(function () {
          console.log("Defining anchor ad slot...");

          // Define the anchor ad slot and store it in the anchorSlotRef
          anchorSlotRef.current = googletag
            .defineSlot(
              "/22082859479/ca-pub-5200956238394958-tag/ansh.quiztwiz_anchor",
              [
                [320, 100],
                [320, 50],
              ],
              "div-gpt-ad-1730893714393-0"
            )
            .addService(googletag.pubads());

          console.log("Adding slotRenderEnded event listener...");
          googletag
            .pubads()
            .addEventListener("slotRenderEnded", function (event) {
              if (
                event.slot.getAdUnitPath() ===
                "/22082859479/ca-pub-5200956238394958-tag/ansh.quiztwiz_anchor"
              ) {
                console.log("Anchor ad slotRenderEnded event triggered");
                if (!event.isEmpty) {
                  setIsAdLoaded(true);
                  console.log("Anchor ad loaded successfully");
                } else {
                  console.log("Anchor ad slot is empty");
                }
              }
            });

          console.log("Enabling single request and services...");
          googletag.pubads().enableSingleRequest();
          googletag.enableServices();

          console.log("Displaying anchor ad slot...");
          googletag.display("div-gpt-ad-1730893714393-0");
        });
      } catch (error) {
        console.error("Error displaying ad:", error);
      }
    };

    const timeoutId = setTimeout(displayAd, 100);
    return () => clearTimeout(timeoutId);
  }, [isVisible]);

  const handleClose = () => {
    try {
      if (anchorSlotRef.current) {
        console.log("Destroying anchor ad slot...");
        googletag.cmd.push(function () {
          googletag.destroySlots([anchorSlotRef.current]);
        });
      }
      setIsVisible(false);
      setIsAdLoaded(false);
    } catch (error) {
      console.error("Error destroying ad slot:", error);
      setIsVisible(false);
      setIsAdLoaded(false);
    }
  };

  if (!isVisible) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 m-auto bg-white flex justify-center z-50 w-[320px] h-[100px]">
      <div className="relative">
        {isAdLoaded && (
          <button
            onClick={handleClose}
            className="absolute -top-8 right-0 bg-gray-800 text-white px-2 py-1 rounded-t-md hover:bg-gray-700 z-50"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        )}
        <div id="div-gpt-ad-1730893714393-0" />
      </div>
    </div>
  );
}
